import * as React from 'react'
import * as css from './RegisterNow.module.css'
import { BlogContext } from 'components/blog/BlogContext'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const RegisterNow = () => {
  const { cta } = React.useContext(BlogContext).blog

  return (
    <div className={css.root}>
      <GatsbyImage
        image={getImage(cta.image.asset)}
        alt="Register now"
      />

      <div className={`${css.txt} richtext`}>
        <h2 className="h3 cps-navy">{cta.title}</h2>
        <p>{cta.description}</p>

        <Link to="/register" className="action">
          Register now
        </Link>
      </div>
    </div>
  )
}

export default RegisterNow
