import * as React from 'react'
import * as css from './ReadNext.module.css'
import { BlogContext } from './BlogContext'
import ArticlePreview from './ArticlePreview'

const ReadNext = ({ current }) => {
  const { allPosts } = React.useContext(BlogContext)

  const next3 = allPosts
    .filter(post => post.title !== current.title)
    .slice(0, 3)

  return (
    <section className={css.root}>
      <h2 className="h2 uppercase cps-navy">Read this next</h2>

      <ul className={css.list}>
        {next3.map((post, key) => (
          <li key={key}>
            <ArticlePreview post={post} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ReadNext
