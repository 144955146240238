import * as React from 'react'
import * as css from './Article.module.css'
import CategoryAndDate from 'components/blog/CategoryAndDate'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Article = ({ post }) => (
  <article className="richtext">
    <h1 className="h1 cps-navy">{post.title}</h1>

    <CategoryAndDate {...post} />

    {post.featuredImage && (
      <figure className="text-center">
        <GatsbyImage
          image={getImage(post.featuredImage.asset)}
          alt={post.title}
        />
      </figure>
    )}

    <SanityBlock className={css.body} body={post._rawBody} />

    <img className={css.icon} src="/favicon.svg" alt="" />
  </article>
)

export default Article
