import * as React from 'react'
import * as css from './PostSidebar.module.css'
import { Link } from 'gatsby'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import { EmailShare, LinkedInShare, TwitterShare, FacebookShare } from 'components/blog/Share'

const PostSidebar = ({ location }) => {
  const shareUrl = location.pathname

  return (
    <aside>
      <div className={`${css.list} sticky`}>
        <div className={css.back}>
          <Link className="with-icon" to="..">
            <HiArrowNarrowLeft />
            <span className="mobile">Back</span>
          </Link>
        </div>

        <EmailShare url={shareUrl} />
        <LinkedInShare url={shareUrl} />
        <TwitterShare url={shareUrl} />
        <FacebookShare url={shareUrl} />
      </div>
    </aside>
  )
}

export default PostSidebar
