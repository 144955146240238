import * as React from 'react'
import * as css from './post.module.css'
import { graphql } from 'gatsby'
import { BlogProvider } from 'components/blog/BlogContext'
import { GlobalContext, GlobalProvider } from 'components/common/GlobalContext'
import DefaultLayout from 'layouts/Default'
import PostSidebar from 'components/blog/PostSidebar'
import ReadNext from 'components/blog/ReadNext'
import Article from 'components/blog/Article'
import RegisterNow from 'components/cta/RegisterNow'
import { Helmet } from 'react-helmet'

const App = ({ data, location }) => {
  const { isAgent } = React.useContext(GlobalContext)

  const post = data.sanityBlogPost
  const categories = post.category.map(({ title }) => title)

  return <>
    <Helmet>
      {categories.includes('Urgent News') && <meta name="robots" content="noindex" />}
    </Helmet>

    <DefaultLayout className="container padding" {...post.seo}>
      <div className={css.grid}>
        <PostSidebar location={location} />

        <Article post={post} />

        {!isAgent && (
          <aside className={css.right}>
            <div className="sticky">
              <RegisterNow />
            </div>
          </aside>
        )}
      </div>

      <hr className={css.divider} />

      <ReadNext current={post} />
    </DefaultLayout>
  </>
}

const BlogPost = props => (
  <GlobalProvider>
    <BlogProvider>
      <App {...props} />
    </BlogProvider>
  </GlobalProvider>
)

export default BlogPost

export const query = graphql`query BlogPost($id: String) {
  sanityBlogPost(id: {eq: $id}) {
    title
    _rawBody
    category {
      title
    }
    publishDate
    formattedPublishDate: publishDate(formatString: "MMM d, YYYY")
    featuredImage { asset { gatsbyImageData(placeholder: NONE) } }
    seo {
      title
      description
    }
  }
}`
